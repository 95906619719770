import { faMapPin, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, navigate } from 'gatsby';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { Slide, toast, ToastContainer } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import isEmail from 'validator/lib/isEmail';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

// import 'react-toastify/dist/ReactToastify.css'; // Can't import here directly

const MAX_MESSAGE_LENGTH = 1500;

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.handleContactFormChange = this.handleContactFormChange.bind(this);
    this.handleRecaptchaToken = this.handleRecaptchaToken.bind(this);
    this.handleRecaptchaError = this.handleRecaptchaError.bind(this);
    this.handleRecaptchaExpired = this.handleRecaptchaExpired.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePhoneNumberDisplayButtonClick = this.handlePhoneNumberDisplayButtonClick.bind(this);
    this.handleEmailDisplayButtonClick = this.handleEmailDisplayButtonClick.bind(this);
    this.handleMessageCopyCheckBoxChange = this.handleMessageCopyCheckBoxChange.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);

    this.recaptchaRef = React.createRef();

    // When building page (static html) with Gatsby, window is not defined and we must skip the
    // following code.
    if (typeof window !== 'undefined') {
      // Only execute following code when we are in the browser (window is defined).
      window.recaptchaOptions = {
        useRecaptchaNet: true,
      };
    }

    const { location, data: { markdownFileText: { frontmatter } } } = this.props;

    // Default value for the form subject (value depends on language).
    let subject = frontmatter.formSubjectOption1Text;

    // If we are coming from an other page, we change the form subject dynamically.
    if (typeof (location.state) !== 'undefined' && location.state !== null) {
      if (typeof (location.state.sourcePage) !== 'undefined') {
        subject = location.state.sourcePage;
      }
    }

    this.state = {
      validated: false,
      isLoading: false,
      lastName: '',
      firstName: '',
      enterprise: '',
      email: '',
      phone: '',
      subject,
      message: '',
      validEmail: true,
      invalidFormToastId: '',
      displayPhoneNumber: false,
      displayEmail: false,
    };
  }

  // Avoid spam by displaying phone number after user clicked on a button.
  handlePhoneNumberDisplayButtonClick() {
    this.setState({ displayPhoneNumber: true });
  }

  // Avoid spam by displaying email after user clicked on a button.
  handleEmailDisplayButtonClick() {
    this.setState({ displayEmail: true });
  }

  handleContactFormChange(event) {
    const { validated, invalidFormToastId } = this.state;

    const { target: { name, value } } = event;

    // Test if a toast is displayed now for a validation error.
    // If it is the case, we close it.
    if (toast.isActive(invalidFormToastId)) {
      toast.dismiss(invalidFormToastId);
    }

    this.setState({
      [name]: value,
    }, () => {
      // Only if needed, we check the form validity.
      if (validated) {
        this.checkFormValidity();
      }
    });
  }

  handleMessageCopyCheckBoxChange(event) {
    this.setState({ messageCopy: event.target.checked });
  }

  handleRecaptchaToken(recaptchaToken) {
    // Waiting for a response.
    this.setState({
      isLoading: true,
    }, () => {
      // When recaptcha is ok, we can send the form values with the token.

      const { pathContext: { locale }, data: { markdownFileText: { frontmatter } } } = this.props;

      const {
        lastName,
        firstName,
        enterprise,
        email,
        phone,
        subject,
        message,
      } = this.state;

      axios.post('https://europe-west1-datart-website.cloudfunctions.net/handleMainForm',
        {
          recaptchaToken,
          lastName,
          firstName,
          enterprise,
          email,
          phone,
          language: locale,
          subject,
          message,
        })
        .then((response) => {
          // Captcha reinitialized.
          this.recaptchaRef.current.reset();

          toast.success(
            <p>
              {frontmatter.successToastPart1Text}
              <br />
              {frontmatter.successToastPart2Text}
            </p>, {
              className: 'rounded text-center',
            },
          );

          // Clear fields.
          this.setState({
            isLoading: false,
            firstName: '',
            lastName: '',
            enterprise: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
          });
        })
        .catch((error) => {
          // Captcha reinitialized.
          this.recaptchaRef.current.reset();

          toast.error(
            <p>
              {frontmatter.errorToastPart1Text}
              <br />
              {frontmatter.errorToastPart2Text}
            </p>, {
              className: 'rounded text-center',
            },
          );

          this.setState({
            isLoading: false,
          });
        });
    });
  }

  handleRecaptchaError() {
    this.recaptchaRef.current.reset();
  }

  handleRecaptchaExpired() {
    this.recaptchaRef.current.reset();
  }

  checkFormValidity() {
    // We only need to check the email (not empty and validity).
    // The subject contains a default value in all cases (can't be empty).

    const {
      email,
    } = this.state;

    // By default, the email is valid (and the form too).
    let validEmail = true;

    if (!email || !isEmail(email, { allow_utf8_local_part: false })) {
      validEmail = false;
    }

    this.setState({
      validEmail,
    });

    // The form is invalid if the email is invalid.
    return !!validEmail;
  }

  handleFormSubmit(event) {
    event.preventDefault();

    // const form = event.currentTarget;
    //
    // if (!form.checkValidity()) {
    //   event.stopPropagation();
    //
    //   this.setState({ validated: true });
    // } else {
    //   // Form is valid (no need to display validation anymore).
    //   this.setState({ validated: false });
    //
    //   // Execute recaptcha before sending form.
    //   this.recaptchaRef.current.execute();
    // }

    if (this.checkFormValidity() === false) {
      // Form is invalid (need to display validation).

      const { data: { markdownFileText: { frontmatter } } } = this.props;

      // Check if a toast is already displayed for a validation error (avoid to have multiple toast
      // at the same time for this error).
      const { invalidFormToastId } = this.state;

      let toastId = invalidFormToastId;

      if (!toast.isActive(toastId)) {
        toastId = toast.error(
          <p>
            {frontmatter.invalidFieldsToastPart1Text}
            <br />
            {frontmatter.invalidFieldsToastPart2Text}
          </p>, {
            className: 'rounded text-center',
          },
        );
      }

      this.setState({
        validated: true,
        invalidFormToastId: toastId,
      });
    } else {
      // Form is valid (no need to display validation anymore).
      // Execute recaptcha before sending form.
      this.setState({
        validated: false,
      }, () => this.recaptchaRef.current.execute());
    }
  }

  handleLanguageChange(eventKey) {
    const { location } = this.props;
    const {
      fullName,
      email,
      phone,
      message,
      messageCopy,
    } = this.state;

    if (eventKey === 'fr') {
      // Go to the french version of the current page.
      // Put in parameters eventual current form values.
      navigate(`/fr${location.pathname}`, {
        state: {
          fullName, email, phone, message, messageCopy,
        },
      });
    } else {
      // Go to the default (english) version of the current page.
      // Put in parameters eventual current form values.
      const regex = new RegExp('/fr/');

      navigate(location.pathname.replace(regex, '/'), {
        state: {
          fullName, email, phone, message, messageCopy,
        },
      });
    }
  }

  // TODO: Accessibility of website (aria...)
  render() {
    const {
      location,
      pathContext: { locale, pageSlugs },
      data: { markdownFileText: { frontmatter } },
    } = this.props;

    const {
      validated,
      isLoading,
      lastName,
      firstName,
      enterprise,
      email,
      phone,
      subject,
      message,
      validEmail,
      displayPhoneNumber,
      displayEmail,
    } = this.state;

    // If we don't need validation, we keep default values to display nothing for validation.
    let isLastNameValid = false;
    let isFirstNameValid = false;
    let isEnterpriseValid = false;
    let isEmailValid = false;
    let isPhoneValid = false;
    let isSubjectValid = false;
    let isMessageValid = false;

    if (validated) {
      isLastNameValid = true;
      isFirstNameValid = true;
      isEnterpriseValid = true;
      isEmailValid = validEmail;
      isPhoneValid = true;
      isSubjectValid = true;
      isMessageValid = true;
    }

    return (
      <Layout
        location={location}
        locale={locale}
        pageSlugs={pageSlugs}
        contactPageLanguageChange={this.handleLanguageChange}
      >
        <SEO
          title={frontmatter.title}
          description={frontmatter.description}
          language={locale}
        />
        {/* <Container className="bg-white p-4 min-vh-100 shadow"> */}
        <iframe
          id="location"
          title={frontmatter.iframeTitle}
          height="550"
          allowFullScreen
          className="border-0 w-100"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2243.683456190522!2d6.685164116
               916689!3d46.50041636284528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c2f
               3d042f0007%3A0xed2e4063fafb6ade!2sGrand-Rue+28%2C+1095+Lutry!5e0!3m2!1sfr!2sch!4v1565
               609965847!5m2!1sfr!2sch"
        />
        {/* <p className="mt-2 text-center lead"> */}
        {/*  DatArt SA */}
        {/*  <br /> */}
        {/*  Grand-Rue 28 */}
        {/*  <br /> */}
        {/*  1095 Lutry */}
        {/* </p> */}
        {/* <p className="text-center lead"> */}
        {/*  {frontmatter.phoneText} */}
        {/*  {' '} */}
        {/*  {displayPhoneNumber ? ( */}
        {/*    <a */}
        {/*      href="tel:+41-00-000-00-00" */}
        {/*      className="font-weight-bold underline-animation" */}
        {/*    > */}
        {/*      +41 00 000 00 00 */}
        {/*    </a> */}
        {/*  ) : ( */}
        {/*    <Button */}
        {/*      type="button" */}
        {/*      size="sm" */}
        {/*      onClick={this.handlePhoneNumberDisplayButtonClick} */}
        {/*    > */}
        {/*      <FontAwesomeIcon icon={faPlus} /> */}
        {/*    </Button> */}
        {/*  )} */}
        {/* </p> */}
        {/* <p className="text-center lead"> */}
        {/*  {frontmatter.emailText} */}
        {/*  {' '} */}
        {/*  {displayEmail ? ( */}
        {/*    <a */}
        {/*      href="mailto:test@test.com" */}
        {/*      className="font-weight-bold underline-animation" */}
        {/*    > */}
        {/*      test@test.com */}
        {/*    </a> */}
        {/*  ) : ( */}
        {/*    <Button */}
        {/*      type="button" */}
        {/*      size="sm" */}
        {/*      onClick={this.handleEmailDisplayButtonClick} */}
        {/*    > */}
        {/*      <FontAwesomeIcon icon={faPlus} /> */}
        {/*    </Button> */}
        {/*  )} */}
        {/* </p> */}
        {/* <Container className="p-4 min-vh-100"> */}
        <Container id="contact" className="p-4">
          {/* <h1 className="text-info font-weight-600"> */}
          {/*  /!* <FontAwesomeIcon className="mr-3" icon={faUser} size="xs" /> *!/ */}
          {/*  {markdownFileText.frontmatter.mainTitle} */}
          {/* </h1> */}
          {/* <h2 className="h3">Emplacement</h2> */}
          {/* <p className="text-center lead font-weight-bold">Need advice,
          information or a quote?</p> */}
          {/* <h2 id="contact" className="h3">{markdownFileText.frontmatter.secondaryTitle}</h2> */}
          <h2 className="h4 text-center">{frontmatter.formTitle}</h2>
          <p className="text-center lead">
            DatArt SA
            <br />
            Grand-Rue 28
            <br />
            1095 Lutry
          </p>
          {/* <p className="text-center lead"> */}
          {/*  {frontmatter.phoneText} */}
          {/*  {' '} */}
          {/*  {displayPhoneNumber ? ( */}
          {/*    <a */}
          {/*      href="tel:+41-00-000-00-00" */}
          {/*      className="font-weight-bold underline-animation" */}
          {/*    > */}
          {/*      +41 00 000 00 00 */}
          {/*    </a> */}
          {/*  ) : ( */}
          {/*    <Button */}
          {/*      type="button" */}
          {/*      size="sm" */}
          {/*      onClick={this.handlePhoneNumberDisplayButtonClick} */}
          {/*    > */}
          {/*      <FontAwesomeIcon icon={faPlus} /> */}
          {/*    </Button> */}
          {/*  )} */}
          {/* </p> */}
          <p className="text-center lead">
            {frontmatter.emailText}
            {' '}
            {displayEmail ? (
              <a
                href="mailto:info@datart.ch"
                className="font-weight-bold underline-animation"
              >
                info@datart.ch
              </a>
            ) : (
              <Button
                type="button"
                size="sm"
                onClick={this.handleEmailDisplayButtonClick}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </p>
          {/* Remove temporally the form with other contact options */}
          {/* <Form */}
          {/*  noValidate */}
          {/*  onSubmit={this.handleFormSubmit} */}
          {/* > */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formLastName"> */}
          {/*      <Form.Label>{frontmatter.formSurnameLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="lastName" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isLastNameValid} */}
          {/*        value={lastName} */}
          {/*      /> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formFirstName"> */}
          {/*      <Form.Label>{frontmatter.formFirstNameLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="firstName" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isFirstNameValid} */}
          {/*        value={firstName} */}
          {/*      /> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formEnterprise"> */}
          {/*      <Form.Label>{frontmatter.formCompanyLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="text" */}
          {/*        name="enterprise" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isEnterpriseValid} */}
          {/*        value={enterprise} */}
          {/*      /> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formEmail"> */}
          {/*      <Form.Label>{frontmatter.formEmailLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="email" */}
          {/*        placeholder={frontmatter.formEmailPlaceHolder} */}
          {/*        maxLength="254" */}
          {/*        name="email" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isEmailValid} */}
          {/*        isInvalid={!validEmail} */}
          {/*        value={email} */}
          {/*      /> */}
          {/*      <Form.Control.Feedback type="invalid"> */}
          {/*        {frontmatter.formInvalidEmailText} */}
          {/*      </Form.Control.Feedback> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formPhoneNumber"> */}
          {/*      <Form.Label>{frontmatter.formTelephoneLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        type="tel" */}
          {/*        name="phone" */}
          {/*        maxLength="255" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isPhoneValid} */}
          {/*        value={phone} */}
          {/*      /> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={5} controlId="formSubject"> */}
          {/*      <Form.Label>{frontmatter.formSubjectLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        as="select" */}
          {/*        name="subject" */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isSubjectValid} */}
          {/*        value={subject} */}
          {/*      > */}
          {/*        <option>{frontmatter.formSubjectOption1Text}</option> */}
          {/*        <option>{frontmatter.formSubjectOption2Text}</option> */}
          {/*        <option>{frontmatter.formSubjectOption3Text}</option> */}
          {/*        <option>{frontmatter.formSubjectOption4Text}</option> */}
          {/*      </Form.Control> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  <Form.Row> */}
          {/*    <Form.Group as={Col} md={8} controlId="formMessage"> */}
          {/*      <Form.Label>{frontmatter.formMessageLabel}</Form.Label> */}
          {/*      <Form.Control */}
          {/*        as="textarea" */}
          {/*        name="message" */}
          {/*        rows="10" */}
          {/*        maxLength={MAX_MESSAGE_LENGTH} */}
          {/*        onChange={this.handleContactFormChange} */}
          {/*        isValid={isMessageValid} */}
          {/*        value={message} */}
          {/*      /> */}
          {/*      <Form.Text className="text-right"> */}
          {/*        {MAX_MESSAGE_LENGTH - message.length} */}
          {/*        / */}
          {/*        {MAX_MESSAGE_LENGTH} */}
          {/*      </Form.Text> */}
          {/*    </Form.Group> */}
          {/*  </Form.Row> */}
          {/*  {isLoading ? ( */}
          {/*    <Button type="submit" disabled> */}
          {/*      <Spinner */}
          {/*        as="span" */}
          {/*        animation="border" */}
          {/*        size="sm" */}
          {/*        role="status" */}
          {/*        aria-hidden="true" */}
          {/*      /> */}
          {/*      <span className="sr-only">{frontmatter.formButtonSrOnly}</span> */}
          {/*    </Button> */}
          {/*  ) : ( */}
          {/*    <Button type="submit">{frontmatter.formButtonText}</Button> */}
          {/*  )} */}
          {/* </Form> */}
          {/* <p className="small">{frontmatter.formRequiredFieldsText}</p> */}
          {/* <ReCAPTCHA */}
          {/*  ref={this.recaptchaRef} */}
          {/*  size="invisible" */}
          {/*  sitekey="6Ld7AtsUAAAAAL1YRmy6q660ymcsiFdiDIElubIY" */}
          {/*  onChange={this.handleRecaptchaToken} */}
          {/*  onErrored={this.handleRecaptchaError} */}
          {/*  onExpired={this.handleRecaptchaExpired} */}
          {/*  badge="inline" */}
          {/* /> */}
          {/* <ToastContainer */}
          {/*  position="bottom-center" */}
          {/*  autoClose={10000} */}
          {/*  closeOnClick={false} */}
          {/*  transition={Slide} */}
          {/* /> */}
        </Container>
      </Layout>
    );
  }
}

Contact.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`  
    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "contact"}}) {
            frontmatter {
                title
                description

                iframeTitle
                
                phoneText
                emailText

                formTitle
                formSurnameLabel
                formFirstNameLabel
                formCompanyLabel
                formEmailLabel
                formEmailPlaceHolder
                formInvalidEmailText
                formTelephoneLabel
                formSubjectLabel
                formSubjectOption1Text
                formSubjectOption2Text
                formSubjectOption3Text
                formSubjectOption4Text
                formSubjectOption5Text
                formSubjectOption6Text
                formSubjectOption7Text
                formMessageLabel
                formButtonText
                formButtonSrOnly
                formRequiredFieldsText

                invalidFieldsToastPart1Text
                invalidFieldsToastPart2Text

                successToastPart1Text
                successToastPart2Text

                errorToastPart1Text
                errorToastPart2Text
            }
        }
    }
`;
